import { Details } from 'govuk-react'
import React from 'react'
import styles from './Details.module.scss'

export interface DetailsProps {
  /** The content that will be displayed when details are revealed */
  children: React.ReactNode
  /** Flag to indicate whether to show component open by default */
  setOpen?: boolean
  /** Text for the details summary link e.g. Help with nationality */
  summary: string
}

/** A details dropdown using GDS components from gov-uk library, made to be compatible with Formik.*/
export const DetailDropdown: React.FunctionComponent<DetailsProps> = ({
  children,
  setOpen,
  summary
}) => {
  return (
    <Details className={styles.details} summary={summary} open={setOpen}>
      {children}
    </Details>
  )
}

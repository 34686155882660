import { Form, Formik } from 'formik'
import { BackLink, Button } from 'govuk-react'
import React, { useEffect } from 'react'
import { animated, useSpring } from 'react-spring'
import * as yup from 'yup'
import { useGetCategoriesQuery } from '../../graphql/generated/schema'
import { useGraphQlErrors } from '../../hooks/useGraphQlErrors'
import { KeyEventsModalFormData } from '../../types'
import { SelectField } from '../SelectField'
import { TextAreaField } from '../TextAreaField'
import styles from './KeyEventsForm.module.scss'
import { getFormValues, setFormValuesToSubmit } from './utils/helper'

const FIELD_LOADING_LABEL = 'Loading...'
const FIELD_INITIAL_LABEL = 'Select a category'

//** REVISE BEFORE UAT WITH DESIGN */
const formErrorMessages = {
  noDetails: 'Details are required.',
  noPages: 'Pages are required.',
  noCommaAtEnd: 'Pages should not end with a comma.',
  repeatPages: 'Pages must not be duplicated.',
  notRealPages: 'One or more pages exceed the document limit.'
}

export interface KeyEventsFormProps {
  /** Handle back button click */
  onBackButtonClick: () => void
  /** Handle add Key Event button click */
  handleKeyEventUpsert: (data: KeyEventsModalFormData) => void

  pageCount: number

  initialFormValues: KeyEventsModalFormData
}

/** KeyEventsModal description.*/
export const KeyEventsForm: React.FunctionComponent<KeyEventsFormProps> = ({
  onBackButtonClick,
  handleKeyEventUpsert,
  initialFormValues,
  pageCount
}) => {
  const validationSchema = yup.object({
    body: yup.string().required(formErrorMessages.noDetails).nullable(),
    pages: yup
      .string()
      .required(formErrorMessages.noPages)
      .nullable()
      .test(
        'noCommaAtEnd',
        formErrorMessages.noCommaAtEnd,
        (value) => !value?.endsWith(',')
      )
      .matches(/^\d+(?:, ?\d+)*$/, 'Page numbers separated by comma')
      .test('repeatPages', formErrorMessages.repeatPages, function (value) {
        if (value && value.length > 0) {
          const pages = value.split(',').map(Number)
          const noDuplicatePage = new Set(pages)
          return pages.length === noDuplicatePage.size
        }
        return false
      })
      .test('notRealPages', formErrorMessages.notRealPages, function (value) {
        if (value) {
          const pages = value.split(',').map(Number)
          const largestPage = Math.max(...pages)
          return largestPage <= pageCount
        }
        return false
      })
  })

  const { addGraphQlError } = useGraphQlErrors()

  const {
    data: categoriesData,
    loading: categoriesLoading,
    error: categoriesError
  } = useGetCategoriesQuery()

  useEffect(() => {
    if (categoriesError) {
      addGraphQlError('categoriesError', 'Unable to load categories')
    }
  }, [categoriesError, addGraphQlError])

  const handleCategoriesChange = async (
    categoryValue: string,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    setFieldValue: (field: string, value: any) => void
  ) => {
    setFieldValue('category', categoryValue)
  }

  const handleSubmit = async (values: KeyEventsModalFormData) => {
    handleKeyEventUpsert(values)
  }

  const props = useSpring({
    to: { opacity: 1 },
    from: { opacity: 0 },
    transform: { 'translateX(0%)': 'translateX(-100%)' },
    config: { duration: 800 }
  })

  return (
    <animated.div
      style={props}
      data-testid={'main key events form container'}
      className={styles.Container}
    >
      <Formik
        initialValues={getFormValues(initialFormValues)}
        onSubmit={(values) => {
          handleSubmit(setFormValuesToSubmit(values))
        }}
        validationSchema={validationSchema}
        validateOnBlur={false}
        validateOnChange={false}
      >
        {({ values, setFieldValue }) => {
          return (
            <div className={styles.Container}>
              <div className={styles.backlink}>
                <BackLink
                  href={''}
                  type="button"
                  onClick={(e) => {
                    // Prevent reload of create bundle route
                    e.preventDefault()
                    onBackButtonClick()
                  }}
                >
                  Back
                </BackLink>
              </div>
              <Form className={styles.formContainer}>
                {/* <fieldset className={styles.fieldset}>
                  <RadioField
                    name="dateType"
                    label={<strong>Key event date</strong>}
                    radioOptions={[
                      {
                        label: 'Dated',
                        value: KeyEventDateType.Dated
                      },
                      {
                        label: 'Undated',
                        value: KeyEventDateType.Undated
                      },
                      {
                        label: 'Various',
                        value: KeyEventDateType.MultipleDates
                      }
                    ]}
                    conditionalContent={conditionalContent}
                    onChange={async (event) => {
                      if (event.target.value === KeyEventDateType.Dated) {
                        values.occuredAt = initialFormValues.occuredAt ?? '--'
                      } else {
                        values.occuredAt = null
                      }
                    }}
                  />
                </fieldset> */}
                <div className={styles.textField}>
                  <TextAreaField
                    name="body"
                    label="Key event details"
                    hint=""
                  ></TextAreaField>
                </div>
                <div className={styles.selectField}>
                  <SelectField
                    name="category"
                    label="Category"
                    options={
                      categoriesLoading
                        ? [
                            {
                              label: FIELD_LOADING_LABEL,
                              value: initialFormValues.category
                                ? initialFormValues.category
                                : FIELD_INITIAL_LABEL
                            }
                          ]
                        : categoriesData
                        ? [
                            {
                              label: initialFormValues.category
                                ? initialFormValues.category
                                : FIELD_INITIAL_LABEL,
                              value: initialFormValues.category
                                ? initialFormValues.category
                                : FIELD_INITIAL_LABEL
                            },
                            ...categoriesData.categories.map((category) => {
                              return {
                                label: category.name,
                                value: category.name
                              }
                            })
                          ]
                        : [
                            {
                              label: initialFormValues.category
                                ? initialFormValues.category
                                : FIELD_INITIAL_LABEL,
                              value: initialFormValues.category
                                ? initialFormValues.category
                                : FIELD_INITIAL_LABEL
                            }
                          ]
                    }
                    onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                      handleCategoriesChange(e.target.value, setFieldValue)
                    }}
                  />
                </div>
                <div className={styles.pagesContainer}>
                  <div key={'tagKey'}>
                    <TextAreaField
                      name="pages"
                      label="Pages"
                      hint=""
                    ></TextAreaField>
                  </div>
                </div>
                <div className={styles.submitButton}>
                  <Button margin={2} type="submit">
                    {values.id === '' ? 'Add event' : 'Save event'}
                  </Button>
                </div>
              </Form>
            </div>
          )
        }}
      </Formik>
    </animated.div>
  )
}

import React, { useEffect, useRef, useState } from 'react'
import { KeyEvent } from '../../types'
import { DetailDropdown } from '../Details'
import { KeyEventListItem } from '../KeyEventListItem'
import styles from './KeyEventList.module.scss'

export interface KeyEventListProps {
  /** List of key events for the selected document */
  keyEvents: KeyEvent[]

  /** Actions to invoke when the delete key event button is clicked. */
  onDeleteKeyEventClick: (keyEventId: string) => void

  /** Actions to invoke when the Edit key event button is clicked. */
  onEditKeyEventClick: (keyEventId: string) => void
}

/** Displays list of document items.*/
export const KeyEventList: React.FunctionComponent<KeyEventListProps> = ({
  keyEvents,
  onDeleteKeyEventClick,
  onEditKeyEventClick
}) => {
  const [keyEventCount, setKeyEventCount] = useState(0)
  const [enableUiBlock] = useState(false)

  const [keyEventsList, setKeyEventsList] = useState<KeyEvent[]>(keyEvents)
  const [keyEventCategory, setKeyEventCategory] = useState<string[]>()

  // Store end of list element to automate scroll to last items added
  const endOfList = useRef<HTMLDivElement | null>(null)

  // Activate scroll to end of list when new key events are added
  useEffect(() => {
    if (keyEventsList.length > keyEventCount) {
      if (keyEventCount > 0) {
        endOfList.current?.scrollIntoView({ behavior: 'smooth' })
      }
      setKeyEventCount(keyEventsList.length)
    }

    setKeyEventCategory(
      Array.from(
        new Set(
          keyEvents
            .map((keyEvent) => keyEvent.category)
            .filter(
              (category): category is string =>
                category !== null && category !== undefined
            )
        )
      )
    )
  }, [keyEventsList, keyEventCount, keyEvents])

  // Render the updated values for the key events
  useEffect(() => {
    setKeyEventsList(keyEvents)
  }, [keyEvents, setKeyEventsList])

  return (
    <div
      className={`${styles.container} ${styles.containerHeight}`}
      data-testid={'key-event-list'}
    >
      {keyEventsList.length > 0 && (
        <div className={enableUiBlock ? styles.disabled : ''}>
          {keyEventCategory?.map((category) => (
            <DetailDropdown key={category} summary={category}>
              {keyEventsList
                .filter((keyEvent) => keyEvent.category === category)
                .map((keyEvent) => (
                  <KeyEventListItem
                    key={keyEvent.id}
                    keyEvent={keyEvent}
                    onDeleteKeyEventClick={onDeleteKeyEventClick}
                    onEditKeyEventClick={onEditKeyEventClick}
                  />
                ))}
            </DetailDropdown>
          ))}
        </div>
      )}
    </div>
  )
}

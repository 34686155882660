import { IoMdClose } from '@react-icons/all-files/io/IoMdClose'
import { FormikProps } from 'formik'
import { Button } from 'govuk-react'
import React, { ChangeEvent, useCallback } from 'react'
import Modal from 'react-modal'
import { CompleteDrsBundleForm } from '../CompleteDrsBundleForm'
import styles from './CompleteDrsBundlePopUp.module.scss'

export interface CompleteDrsBundlePopUpProps {
  /**
   * String for the bundle expiry date and time
   */
  bundleExpiresAt: string
  /**
   * Label for the radion button if displayed
   */
  radioButtonLabel: string | null
  /**
   * Hint for the radio button if displayed
   */
  radioButtonHint: string
  /**
   * Set confirmation pop up to be open(true) or closed(false)
   */
  isOpen: boolean
  /**
   * Function invoked when pop up is closed
   */
  onCloseModal: () => void
  /**
   * Function invoked when the confirmation button is clicked
   */
  onConfirmationButtonClick: () => void
  /**
   * Function invoked when the toggle is switched
   */
  onRadioButtonChange: (e: ChangeEvent<HTMLInputElement>) => void
  /**
   * Function to set the Formik ref
   */

  setFormikRef: (formikRef: FormikProps<{ uploadToDrs: string }> | null) => void
}

/** A popup window implemented with the npm package react-modal which prompts the user to confirm their decision.*/
export const CompleteDrsBundlePopUp: React.FunctionComponent<
  CompleteDrsBundlePopUpProps
> = ({
  isOpen,
  onCloseModal,
  onConfirmationButtonClick,
  onRadioButtonChange,
  bundleExpiresAt,
  radioButtonHint,
  radioButtonLabel,
  setFormikRef
}) => {
  const formikCallbackRef = useCallback(
    (instance: FormikProps<{ uploadToDrs: string }> | null) => {
      setFormikRef(instance)
    },
    [setFormikRef]
  )

  return (
    <Modal
      appElement={document.getElementById('root') as HTMLElement}
      isOpen={isOpen}
      onRequestClose={onCloseModal}
      className={styles.modal}
      overlayClassName={styles.overlay}
    >
      <div role={'region'} aria-labelledby={'modal-content'}>
        <div id={'modal-content'}>
          <button
            title="Close dialogue"
            onClick={onCloseModal}
            className={styles.closeBtn}
          >
            <IoMdClose />
          </button>
          <CompleteDrsBundleForm
            bundleExpiresAt={bundleExpiresAt}
            radioButtonLabel={radioButtonLabel}
            radioButtonHint={radioButtonHint}
            onRadioButtonChange={onRadioButtonChange}
            setFormikRef={formikCallbackRef}
          />
          <div className={styles.popUpButtonDiv}>
            <Button
              margin={2}
              type="button"
              onClick={onConfirmationButtonClick}
            >
              Complete bundle
            </Button>
            <Button
              margin={2}
              buttonShadowColour="#A9A9A9"
              buttonTextColour="#0b0c0c"
              buttonHoverColour="#ffdd00"
              buttonColour="#f3f2f1"
              onClick={onCloseModal}
              name="Cancel"
            >
              Cancel
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  )
}

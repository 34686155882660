import { Spinner } from 'govuk-react'
import React, { useCallback, useEffect, useState } from 'react'
import { animated, useSpring } from 'react-spring'
import {
  DocumentAnalysisStatus,
  KeyEvent,
  KeyEventDateType,
  namedOperations,
  useCreateKeyEventMutation,
  useDeleteKeyEventMutation,
  useGetDocumentQuery,
  useGetKeyEventsByDocumentLazyQuery,
  useUpdateKeyEventMutation
} from '../../graphql/generated/schema'
import { useGraphQlErrors } from '../../hooks/useGraphQlErrors'
import { useModal } from '../../hooks/useModal'
import { KeyEventStatus, KeyEventsModalFormData } from '../../types'
import { ConfirmationPopUp } from '../ConfirmationPopUp'
import { ErrorRetry } from '../ErrorRetry'
import { KeyEventList } from '../KeyEventList'
import { KeyEventsForm } from '../KeyEventsForm'
import { ListTopPanel } from '../ListTopPanel'
import styles from './KeyEventsPanel.module.scss'

const POLLING_INTERVAL = 5000

const INITIAL_FORM_DATA = {
  occuredAt: '--',
  body: '',
  pages: '',
  id: '',
  dateType: KeyEventDateType.Dated
}
export interface KeyEventsPanelProps {
  documentId: string
  pageCount: number
}

/** Document information panel component which renders the document information form.*/
export const KeyEventsPanel: React.FunctionComponent<KeyEventsPanelProps> = ({
  documentId,
  pageCount
}) => {
  const { graphQlErrors, addGraphQlError } = useGraphQlErrors()
  const [reviewedKeyEventsCount, setReviewedKeyEventsCount] = useState(0)
  const [isKeyEventsGenerated, setIsKeyEventGenerated] = useState(false)
  const [iskeyEventsFormOpen, setIsKeyEventsFormOpen] = useState(false)
  const [keyEventInitialFormData, setKeyEventInitialFormData] =
    useState<KeyEventsModalFormData>(INITIAL_FORM_DATA)
  const [deleteKeyEventId, setDeleteKeyEventId] = useState('')

  const refetchQueries = [
    namedOperations.Query.GetKeyEventsByDocument,
    namedOperations.Query.GetBundleDocumentsByBundleId
  ]

  const handleOpenKeyEventsForm = useCallback(
    (data: KeyEventsModalFormData) => {
      setKeyEventInitialFormData(data)
      setIsKeyEventsFormOpen(true)
    },
    []
  )

  const handleCloseKeyEventsForm = useCallback(() => {
    setKeyEventInitialFormData(INITIAL_FORM_DATA)
    setIsKeyEventsFormOpen(false)
  }, [])

  const {
    isModalOpen: isDeleteKeyEventModalOpen,
    openModal: openDeleteKeyEventModal,
    closeModal: closeDeleteKeyEventModal
  } = useModal()

  const [keyEvents, setKeyEvents] = useState<KeyEvent[]>([])

  // Get document data
  const {
    data: documentData,
    error: documentError,
    refetch: documentRefetch,
    startPolling,
    stopPolling
  } = useGetDocumentQuery({
    variables: {
      documentId
    },
    fetchPolicy: 'network-only'
  })

  const [
    getKeyEvents,
    { data: keyEventsData, error: keyEventsError, refetch: keyEventsRefetch }
  ] = useGetKeyEventsByDocumentLazyQuery({
    variables: {
      documentId
    },
    fetchPolicy: 'network-only'
  })

  const [deleteKeyEventMutation] = useDeleteKeyEventMutation({
    refetchQueries
  })

  useEffect(() => {
    if (keyEventsData?.keyEventsByDocument) {
      setKeyEvents(keyEventsData.keyEventsByDocument)
    }
  }, [keyEventsData, setKeyEvents])

  useEffect(() => {
    if (documentError) {
      addGraphQlError('documentError', 'Document not found')
    }
  }, [documentError, addGraphQlError])

  // Fetch document information with an interval until key events are extracted
  useEffect(() => {
    if (
      documentData?.document?.documentAnalysisStatus !==
        DocumentAnalysisStatus.Completed &&
      documentData?.document?.documentAnalysisStatus !==
        DocumentAnalysisStatus.Error
    ) {
      startPolling(POLLING_INTERVAL)
    } else {
      setIsKeyEventGenerated(true)
      getKeyEvents()
    }
    return () => {
      stopPolling()
    }
  }, [
    documentData,
    startPolling,
    stopPolling,
    setIsKeyEventGenerated,
    getKeyEvents
  ])

  // Fetching the total count of Reviewed key events from the list
  useEffect(() => {
    if (keyEventsData) {
      const reviewedKeyEventsCount = keyEventsData.keyEventsByDocument.filter(
        (keyEvent) => keyEvent.status === KeyEventStatus.Reviewed
      ).length

      setReviewedKeyEventsCount(reviewedKeyEventsCount)
    }
  }, [keyEventsData, setReviewedKeyEventsCount])

  useEffect(() => {
    if (keyEventsError) {
      addGraphQlError(
        'keyEventsError',
        'Could not retrieve key events for the selected document'
      )
    }
  }, [keyEventsError, addGraphQlError])

  const handleRetryClick = useCallback(() => {
    if (keyEventsError) {
      keyEventsRefetch({
        documentId
      })
    }
    if (documentError) {
      documentRefetch({
        documentId
      })
    }
  }, [
    documentId,
    keyEventsError,
    documentError,
    keyEventsRefetch,
    documentRefetch
  ])

  const [updateKeyEvent] = useUpdateKeyEventMutation({
    refetchQueries
  })

  const handleEditKeyEventClick = useCallback(
    (keyEventId: string) => {
      const KeyEvent = keyEventsData?.keyEventsByDocument.find(
        (events) => events.id === keyEventId
      )
      const keyEventFormData = {
        body: KeyEvent?.body ? KeyEvent.body : '',
        occuredAt: KeyEvent?.occuredAt ? KeyEvent.occuredAt : null,
        pages: KeyEvent?.pages ? KeyEvent.pages : '',
        category: KeyEvent?.category ? KeyEvent.category : '',
        id: keyEventId,
        dateType: KeyEvent?.dateType ? KeyEvent.dateType : null
      }

      setKeyEventInitialFormData(keyEventFormData)

      handleOpenKeyEventsForm(keyEventFormData)
    },

    [handleOpenKeyEventsForm, keyEventsData?.keyEventsByDocument]
  )

  const [createKeyEventMutation] = useCreateKeyEventMutation({
    refetchQueries
  })

  const handleKeyEventUpsert = useCallback(
    (data: KeyEventsModalFormData) => {
      if (data.id === '') {
        createKeyEventMutation({
          variables: {
            input: {
              body: data.body,
              documentId: documentId,
              //occuredAt: data.occuredAt,
              category: data.category,
              pages: data.pages,
              dateType: data.dateType
            }
          }
        })
      } else {
        updateKeyEvent({
          variables: {
            input: {
              body: data.body,
              id: data.id,
              //occuredAt: data.occuredAt,
              category: data.category,
              pages: data.pages,
              status: KeyEventStatus.Reviewed,
              dateType: data.dateType
            }
          }
        })
      }

      handleCloseKeyEventsForm()

      setKeyEventInitialFormData(INITIAL_FORM_DATA)
    },

    [
      createKeyEventMutation,
      documentId,
      updateKeyEvent,
      handleCloseKeyEventsForm
    ]
  )

  const handleDeleteKeyEventClick = useCallback(
    (keyEventId: string) => {
      setDeleteKeyEventId(keyEventId)
      openDeleteKeyEventModal()
    },
    [setDeleteKeyEventId, openDeleteKeyEventModal]
  )

  const handleKeyEventDelete = useCallback(() => {
    deleteKeyEventMutation({
      variables: {
        deleteKeyEventId: deleteKeyEventId as string
      }
    })
    closeDeleteKeyEventModal()
  }, [deleteKeyEventMutation, closeDeleteKeyEventModal, deleteKeyEventId])

  const props = useSpring({
    opacity: iskeyEventsFormOpen ? 1 : 0,
    transform: iskeyEventsFormOpen ? 'translateX(0%)' : 'translateX(-100%)'
  })

  return (
    <div
      className={`${styles.container}
    ${!isKeyEventsGenerated && styles.containerHeight}`}
    >
      {graphQlErrors.length > 0 && (
        <>
          <ErrorRetry
            heading="There was a problem with the service"
            onHandleErrorClick={handleRetryClick}
          />
        </>
      )}
      <div className={styles.listTopPanel}>
        <ListTopPanel
          handleAddButtonClick={() => {
            handleOpenKeyEventsForm(INITIAL_FORM_DATA)
          }}
          isAddToListDisabled={false}
          headerLabel={`${reviewedKeyEventsCount} of ${keyEvents.length} key events reviewed`}
          buttonLabel="Add key event"
        />
      </div>
      {!isKeyEventsGenerated && (
        <>
          <div className={styles.loaderText}>
            Key events are being generated
            <Spinner
              fill="white"
              height="25px"
              title="Generating"
              width="25px"
              className={styles.spinner}
            />
          </div>
        </>
      )}
      {keyEvents.length === 0 && (
        <div className={styles.noEventsContainer}>
          <span className={styles.bolderText}>Document has no key events</span>
          <div>
            <br />
            You can click the &quot;+&quot; icon to manually add a key event to
            this document.
          </div>
        </div>
      )}
      {isKeyEventsGenerated && keyEvents.length > 0 && (
        <>
          <div className={styles.keyEventsContainer}>
            <div className={styles.keyEventsList}>
              <KeyEventList
                keyEvents={keyEvents}
                onDeleteKeyEventClick={handleDeleteKeyEventClick}
                onEditKeyEventClick={handleEditKeyEventClick}
              />
            </div>
          </div>
        </>
      )}

      {iskeyEventsFormOpen && (
        <animated.div
          style={props}
          data-testid={'edit key events container'}
          className={` ${styles.editEventsContainer} ${styles.viewEditEventsContainer} `}
        >
          <KeyEventsForm
            onBackButtonClick={handleCloseKeyEventsForm}
            handleKeyEventUpsert={handleKeyEventUpsert}
            initialFormValues={keyEventInitialFormData}
            pageCount={pageCount}
          />
        </animated.div>
      )}

      <ConfirmationPopUp
        title="Are you sure you want to delete this key event?"
        confirmationMessageText={[
          'This action will remove all changes made in this particular key event and will remove it from your key event list. '
        ]}
        isOpen={isDeleteKeyEventModalOpen}
        onCloseModal={closeDeleteKeyEventModal}
        onConfirmationButtonClick={handleKeyEventDelete}
        confirmationButtonText="Yes, delete key event"
      />
    </div>
  )
}

import { Form, Formik, FormikProps } from 'formik'
import { Heading, Paragraph, WarningText } from 'govuk-react'
import React, { ChangeEvent } from 'react'
import * as yup from 'yup'
import { attachFocusToFirstFormElementError } from '../../utils/formikHelper'
import { RadioField } from '../RadioField'
import styles from './CompleteDrsBundleForm.module.scss'

export interface CompleteDrsBundleFormProps {
  /**
   * String for the bundle expiry date and time
   */
  bundleExpiresAt: string
  /**
   * Label for the radion button if displayed
   */
  radioButtonLabel: string | null
  /**
   * Hint for the radio button if displayed
   */
  radioButtonHint: string
  /**
   * Function invoked when the toggle is switched
   */
  onRadioButtonChange: (e: ChangeEvent<HTMLInputElement>) => void
  /**
   * Setting formik ref data
   */
  setFormikRef: (formikRef: FormikProps<{ uploadToDrs: string }>) => void
}

/** Form containing information for the user when completing the bundle.*/
export const CompleteDrsBundleForm: React.FunctionComponent<
  CompleteDrsBundleFormProps
> = ({
  radioButtonHint,
  radioButtonLabel,
  bundleExpiresAt,
  onRadioButtonChange,
  setFormikRef
}) => {
  const initialValues = {
    uploadToDrs: ''
  }

  const formErrorMessages = {
    uploadToDrs: 'Select an option'
  }

  const validationSchema = yup.object({
    uploadToDrs: yup.string().required(formErrorMessages.uploadToDrs)
  })

  return (
    <Formik
      innerRef={setFormikRef}
      initialValues={initialValues}
      validateOnBlur={false}
      validateOnChange={true}
      validationSchema={validationSchema}
      onSubmit={() => {
        return
      }}
    >
      {({ setFieldError, setFieldTouched, isValidating, errors }) => {
        // If validation errors exist, set focus on first error element
        if (!isValidating && errors) {
          attachFocusToFirstFormElementError
        }
        return (
          <Form className={styles.form}>
            <div className={styles.formTitle}>
              <Heading size="L">Complete this bundle</Heading>
            </div>
            <Paragraph>
              You will not be able to make further changes. Choose ‘Cancel’ if
              you’re not finished.
            </Paragraph>
            <div className={styles.warningMessage}>
              <WarningText>{`This bundle must be completed by ${bundleExpiresAt} or it will be deleted.`}</WarningText>
            </div>
            {radioButtonLabel && (
              <div>
                <fieldset className={styles.fieldset}>
                  <RadioField
                    name="uploadToDrs"
                    label={<strong>{radioButtonLabel}</strong>}
                    hint={radioButtonHint}
                    inline={true}
                    radioOptions={[
                      {
                        label: 'Yes',
                        value: 'Yes'
                      },
                      {
                        label: 'No',
                        value: 'No'
                      }
                    ]}
                    onChange={async (e) => {
                      await setFieldTouched('uploadToDrs', true, false)
                      await setFieldError('uploadToDrs', '')
                      onRadioButtonChange(e)
                    }}
                  />
                </fieldset>
              </div>
            )}
          </Form>
        )
      }}
    </Formik>
  )
}

import { IoMdClose } from '@react-icons/all-files/io/IoMdClose'
import { Button, Heading, Paragraph, WarningText } from 'govuk-react'
import React from 'react'
import Modal from 'react-modal'
import styles from './CompleteLocalBundlePopUp.module.scss'

export interface CompleteLocalBundlePopUpProps {
  /**
   * Set confirmation pop up to be open(true) or closed(false)
   */
  isOpen: boolean
  /**
   * Function invoked when pop up is closed
   */
  onCloseModal: () => void
  /**
   * Function invoked when the confirmation button is clicked
   */
  onConfirmationButtonClick: () => void
  /**
   * String for the bundle expiry date and time
   */
  bundleExpiresAt: string
}

/** A popup window implemented with the npm package react-modal which prompts the user to confirm their decision.*/
export const CompleteLocalBundlePopUp: React.FunctionComponent<
  CompleteLocalBundlePopUpProps
> = ({ isOpen, onCloseModal, onConfirmationButtonClick, bundleExpiresAt }) => {
  return (
    <Modal
      appElement={document.getElementById('root') as HTMLElement}
      isOpen={isOpen}
      onRequestClose={onCloseModal}
      className={styles.modal}
      overlayClassName={styles.overlay}
    >
      <div role={'region'} aria-labelledby={'modal-content'}>
        <div id={'modal-content'}>
          <button
            title="Close dialogue"
            onClick={onCloseModal}
            className={styles.closeBtn}
          >
            <IoMdClose />
          </button>
          <div className={styles.popUpTitle}>
            <Heading size="L">Complete this bundle</Heading>
          </div>
          <>
            <Paragraph>
              You will not be able to make further changes. Choose ‘Cancel’ if
              you’re not finished.
            </Paragraph>
          </>
          <div className={styles.warningMessage}>
            <WarningText>{`This bundle must be completed by ${bundleExpiresAt} or it will be deleted.`}</WarningText>
          </div>
          <div className={styles.popUpButtonDiv}>
            <Button
              margin={2}
              type="button"
              onClick={onConfirmationButtonClick}
            >
              Complete bundle
            </Button>
            <Button
              margin={2}
              buttonShadowColour="#A9A9A9"
              buttonTextColour="#0b0c0c"
              buttonHoverColour="#ffdd00"
              buttonColour="#f3f2f1"
              onClick={onCloseModal}
              name="Cancel"
            >
              Cancel
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  )
}

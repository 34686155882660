import { BiCheck as Check } from '@react-icons/all-files/bi/BiCheck'
import { MdDelete as Bin } from '@react-icons/all-files/md/MdDelete'
import { MdEdit as Edit } from '@react-icons/all-files/md/MdEdit'
import { Tag } from 'govuk-react'
import React from 'react'
import { animated, useSpring } from 'react-spring'
import { KeyEvent, KeyEventStatus, StatusTint } from '../../types'
import { LinkButtonWithIcon } from '../LinkButtonWithIcon'
import styles from './KeyEventListItem.module.scss'

export interface KeyEventListItemProps {
  /** key event information */
  keyEvent: KeyEvent

  /** Actions to invoke when the delete key event button is clicked. */
  onDeleteKeyEventClick: (keyEventId: string) => void

  /** Actions to invoke when the edit key event button is clicked. */
  onEditKeyEventClick: (keyEventId: string) => void
}

/** KeyEventListItem displays document name , date, status. On click function to display the document .*/
export const KeyEventListItem: React.FunctionComponent<
  KeyEventListItemProps
> = ({ keyEvent, onDeleteKeyEventClick, onEditKeyEventClick }) => {
  let statusTint: StatusTint | null

  switch (keyEvent.status) {
    case KeyEventStatus.NotReviewed:
      statusTint = StatusTint.RED
      break
    case KeyEventStatus.Reviewed:
      statusTint = StatusTint.GREEN
      break
    default:
      statusTint = StatusTint.RED
  }

  const props = useSpring({
    to: { opacity: 1 },
    from: { opacity: 0 },
    config: { duration: 800 }
  })
  return (
    <animated.div
      style={props}
      data-testid={'container'}
      className={`
      ${styles.container}
      `}
    >
      <div
        role={'button'}
        tabIndex={0}
        className={`${styles.keyEventInformationContainer}  `}
      >
        <div className={styles.firstRowContainer}>
          {keyEvent.occuredAt ? '' : ''}
          <div
            data-testid={'statusContainer'}
            className={`${styles.statusContainer}`}
          >
            <span
              role={'status'}
              data-testid={'status'}
              title={keyEvent.status}
              aria-live={'polite'}
              aria-label={`Key Event Status ${keyEvent.status}`}
              className={styles.status}
            >
              <Tag
                tint={statusTint}
                className={`${
                  keyEvent.status === KeyEventStatus.Reviewed
                    ? styles.shortStatusContainer
                    : styles.longStatusContainer
                }`}
              >
                {keyEvent.status.replace('_', ' ')}
              </Tag>
            </span>
          </div>
        </div>
        <div className={styles.secondRowContainer}>
          <div className={styles.eventTextContainer}>{keyEvent.body}</div>
        </div>
        <div className={styles.thirdRowContainer}>
          <div className={styles.pageContainer}>Page {keyEvent.pages}</div>
          <hr />
          <div className={styles.buttonGroup}>
            <LinkButtonWithIcon
              icon={
                keyEvent.status === KeyEventStatus.NotReviewed ? (
                  <Check size={'20'} />
                ) : (
                  <Edit size={'20'} />
                )
              }
              onClick={() => onEditKeyEventClick(keyEvent.id)}
              className={styles.buttonSpacing}
            >
              {keyEvent.status === KeyEventStatus.NotReviewed
                ? 'Review'
                : 'Edit'}
            </LinkButtonWithIcon>
            <LinkButtonWithIcon
              icon={<Bin size={'20'} />}
              onClick={() => onDeleteKeyEventClick(keyEvent.id)}
            >
              Delete
            </LinkButtonWithIcon>
          </div>
        </div>
      </div>
    </animated.div>
  )
}

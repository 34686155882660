import { KeyEventsModalFormData } from '../../../types'

export const getFormValues = (
  keyEventsModalFormData: KeyEventsModalFormData
) => {
  return {
    ...keyEventsModalFormData
  }
}

export const setFormValuesToSubmit = (
  keyEventsModalFormData: KeyEventsModalFormData
) => {
  return {
    ...keyEventsModalFormData
  }
}
